import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";

import Banner from "../components/banner";

import BlocksText from "../components/blocks/text";
import BlocksFeature from "../components/blocks/feature";
import BlocksImage from "../components/blocks/image";
import BlocksGallery from "../components/blocks/gallery";
import BlocksNews from "../components/blocks/news";

import Recruiting from "../components/recruiting";

const IndexPage = () => {
	const { strapiHome, allStrapiNew } = useStaticQuery(query);

	var sectionIsAlt = false;
	var sectionStyle = 0;

	return (
		<Layout page="index" seo={strapiHome.seo}>
			<Banner banner={strapiHome.banner}/>

			<section id="wrapper">
				{strapiHome.sections.map((section, i) => {
					switch (section.name) {
						case 'blocksText':
							sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
							return (<BlocksText alt={sectionIsAlt} myStyle={sectionStyle}
								text={section.text}
								more={section.more ? section.more : ""}
								urltext={section.urltext ? section.urltext : ""}
								url={section.url ? section.url : ""}/>);
						case 'blocksFeature':
							if (section.text || section.features.length) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								return (<BlocksFeature alt={sectionIsAlt} myStyle={sectionStyle}
									text={section.text ? section.text : ""}
									text2={section.text2 ? section.text2 : ""}
									more={section.more ? section.more : ""}
									urltext={section.urltext ? section.urltext : ""}
									url={section.url ? section.url : ""}
									features={section.features}/>);
							} else {return '';}
						case 'blocksImage':
							if (section.text) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
								return (<BlocksImage alt={sectionIsAlt} myStyle={sectionStyle}
									text={section.text}
									more={section.more ? section.more : ""}
									urltext={section.urltext ? section.urltext : ""}
									url={section.url ? section.url : ""}
									person={section.person}
									image={section.image}/>);
							} else {return '';}
						case 'blocksGallery':
							if (section.images.length) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								return (<BlocksGallery alt={sectionIsAlt} myStyle={sectionStyle}
									text={section.text ? section.text : ""}
									text2={section.text2 ? section.text2 : ""}
									images={section.images}/>);
							} else {return '';}
						case 'blocksNews':
							if (section.limit != null) {
								var helperIsAlt = sectionIsAlt;
								var helperStyle = sectionStyle;
								var numberNewsBlock = section.limit;
								if (section.limit >= allStrapiNew.totalCount) {
									numberNewsBlock = allStrapiNew.totalCount - 1;
								}
								for (i = 0; i <= numberNewsBlock; i++){
									sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
									sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
								}
								return (<BlocksNews alt={helperIsAlt} myStyle={helperStyle}
									limit={section.limit} home={true}/>);
							} else {return '';}
						default: return('');
					}
				})}

				{(() => {
					if (strapiHome.recruiting) {
						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						return (
							<Recruiting alt={sectionIsAlt}/>
						);
					}
					return ('');
				})()}
			</section>
		</Layout>
	);
};

const query = graphql`
	query {
		strapiHome {
			title
			seo {
				metaTitle
				metaDescription
				shareImage {
					url
				}
			}
			banner {
				title
				description
			}
			sections {
				name
				text
				text2
				more
				url
				urltext
				person
				features {
					name
					title
					description
					more
					urltext
					url
				}
				image {
					url
					caption
					alternativeText
				}
				images {
					name
					caption
					image {
						url
						caption
						alternativeText
					}
				}
				limit
			}
			recruiting
		}
		allStrapiNew {
			totalCount
		}
	}
`;

export default IndexPage;
